import * as React from 'react';
import dynamic from 'next/dynamic';
import AddressField from '../AddressField/AddressField';

type Props = {
  field: {
    name: string;
    value: any;
  };
  form: {
    setFieldValue: (name: string, item: any, bool: boolean) => void;
    setFieldTouched: (
      arg0: string,
      arg1: boolean | null | undefined,
      arg2: boolean | null | undefined,
    ) => void;
  };
  validateOnTouched?: boolean;
  validateOnChanged?: boolean;
  isCustomizableAddress: boolean;
};

const AddressCustomForm = dynamic(() =>
  import('../../organisms/Forms/AddressCustomForm'),
);

const AddressInputField = ({
  field: { name, value },
  form: { setFieldValue, setFieldTouched },
  validateOnTouched,
  validateOnChanged,
  isCustomizableAddress,
  ...props
}: Props) => {
  const [openDialogAddress, setOpenDialogAddress] = React.useState(false);

  const handleOpenDialog = React.useCallback(() => {
    setOpenDialogAddress(state => !state);
  }, []);

  const onChange = React.useCallback(
    item => {
      setFieldValue(name, item, validateOnChanged ?? false);
    },
    [name, validateOnChanged],
  );

  const onInputBlur = () =>
    setFieldTouched(name, true, validateOnTouched ?? false);

  // .label for search, .fullAddress for profile address
  const initialInputValue = value?.label || value?.fullAddress || '';

  return (
    <>
      <AddressField
        {...props}
        isCustomizableAddress={isCustomizableAddress}
        setOpenDialogAddress={handleOpenDialog}
        inputProps={{ onBlur: onInputBlur }}
        initialInputValue={initialInputValue}
        onChange={onChange}
        inputValue={value?.customAddress?.fullAddress}
        hasCustomAddress={Boolean(value?.customAddress?.fullAddress)}
      />
      {isCustomizableAddress && (
        <AddressCustomForm
          valuesForm={value}
          hasCustomAddress={!value?.id || value?.customAddress?.fullAddress}
          onChange={onChange}
          setOpenDialogAddress={handleOpenDialog}
          open={openDialogAddress}
        />
      )}
    </>
  );
};

export default AddressInputField;
